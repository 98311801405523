<template>
  <app-layout>
    <page-header>
      Detalii comanda # {{ id }}
      <template #end>
        <a
          v-if="order.invoice"
          :href="order.invoice.pdf"
          target="_blank"
          class="btn"
          >Factura</a
        >
        <router-link
          v-else
          :to="{ name: 'orders.invoices.create' }"
          class="btn-primary mr-2"
        >
          Incarca factura
        </router-link>
        <modal-update-vendor-order-status
          @update:order="
            (order) => {
              this.order = order;
            }
          "
          :order-id="id"
        />
      </template>
    </page-header>

    <page-section class="grid grid-cols-1 lg:grid-cols-3 gap-3">
      <orders-show-card-shipping :order="order" />
      <orders-show-card-billing :order="order" />
      <orders-show-card-payment :order="order" />
    </page-section>

    <page-section>
      <orders-show-card-items :order-id="$route.params.id" />
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import ordersApi from "../api/resources/ordersApi";
import OrdersShowCardItems from "../components/OrdersShowCardItems.vue";
import OrdersShowCardShipping from "../components/OrdersShowCardShipping.vue";
import OrdersShowCardBilling from "../components/OrdersShowCardBilling.vue";
import OrdersShowCardPayment from "../components/OrdersShowCardPayment.vue";
import ModalUpdateVendorOrderStatus from "../components/ModalUpdateVendorOrderStatus.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";

export default {
  components: {
    AppLayout,
    OrdersShowCardItems,
    OrdersShowCardShipping,
    OrdersShowCardBilling,
    OrdersShowCardPayment,
    ModalUpdateVendorOrderStatus,
    PageHeader,
    PageSection,
  },

  props: ["id"],

  data() {
    return {
      order: {},
    };
  },

  created() {
    ordersApi.show(this.$route.params.id).then((data) => {
      this.order = data.data;
    });
  },
};
</script>

<style></style>
