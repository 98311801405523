<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Nr crt</v-table-th>
        <v-table-th>Produs</v-table-th>
        <v-table-th>U.M.</v-table-th>
        <v-table-th>Cant.</v-table-th>
        <v-table-th>Pret</v-table-th>
        <v-table-th>Subtotal</v-table-th>
        <v-table-th>Total</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="(row, index) in rows" :key="row.id">
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ index + 1 }}
          </p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">{{ row.name }}</p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">{{ row.unit }}</p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ row.quantity }}
          </p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ formatPrice(row.price) }}
          </p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ formatPrice(row.subtotal) }}
          </p>
        </v-table-td>
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ formatPrice(row.total) }}
          </p>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VTable from "./VTable.vue";
import VTableTd from "./VTableTd.vue";
import VTableTh from "./VTableTh.vue";
import VTbody from "./VTbody.vue";
import VThead from "./VThead.vue";

export default {
  components: { VTableTd, VTableTh, VTable, VThead, VTbody },

  props: {
    rows: {
      type: Array,
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
