<template>
  <v-card>
    <v-card-body>
      <v-card-title>Detalii factura</v-card-title>
      <ul class="text-sm font-normal text-gray-600">
        <li>Transport: {{ formatPrice(order.shipping) }}</li>
        <li>Total: {{ formatPrice(order.total) }}</li>
        <li>Metoda de plata: {{ order.payment?.method }}</li>

        <li>Status: {{ order.status?.label }}</li>
      </ul>
    </v-card-body>
  </v-card>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VCard from "./VCard.vue";
import VCardBody from "./VCardBody.vue";
import VCardTitle from "./VCardTitle.vue";
export default {
  components: { VCard, VCardBody, VCardTitle },
  props: {
    order: {
      type: Object,
    },
  },
  computed: {},

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
