<template>
  <v-card>
    <loader v-if="loading" class="my-5" />
    <template v-else>
      <template v-if="totalRows">
        <orders-show-card-items-table :rows="rows" />

        <pagination
          @change:page="index($event)"
          :total="meta.total"
          :current_page="meta.current_page"
          :from="meta.from"
          :to="meta.to"
          :next_page_url="links.next"
          :prev_page_url="links.prev"
        />
      </template>

      <no-results v-else class="m-5" />
    </template>
  </v-card>
</template>

<script>
import ordersApi from "../api/resources/ordersApi";
import Loader from "./Loader.vue";
import NoResults from "./NoResults.vue";
import OrdersShowCardItemsTable from "./OrdersShowCardItemsTable.vue";
import Pagination from "./Pagination.vue";
import VCard from "./VCard.vue";

export default {
  props: {
    orderId: String,
  },

  components: {
    Pagination,
    OrdersShowCardItemsTable,
    VCard,
    Loader,
    NoResults,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      ordersApi
        .itemsIndex(this.orderId, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  created() {
    this.index();
  },
};
</script>

<style></style>
