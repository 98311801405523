<template>
  <v-card>
    <v-card-body>
      <v-card-title>Adresa facturare</v-card-title>
      <ul class="text-sm font-normal text-gray-600">
        <template v-if="isCompany">
          <li>Companie: {{ order.company }}</li>
          <li>Cod fiscal: {{ order.fiscal_code }}</li>
          <li>Nr. inreg.: {{ order.registration_number }}</li>
        </template>
        <li>
          Persoana de contact: {{ order.billing_address?.first_name }},
          {{ order.billing_address?.last_name }}
        </li>
        <li>Adresa: {{ order.billing_address?.street }}</li>
        <li>Tel: {{ order.billing_address?.phone }}</li>
      </ul>
    </v-card-body>
  </v-card>
</template>

<script>
import VCard from "./VCard.vue";
import VCardBody from "./VCardBody.vue";
import VCardTitle from "./VCardTitle.vue";
export default {
  components: { VCard, VCardBody, VCardTitle },
  props: {
    order: {
      type: Object,
    },
  },

  computed: {
    isCompany() {
      return this.order?.customer_type?.name == "company";
    },
  },
};
</script>

<style></style>
