<template>
  <button @click="setIsOpen(true)" class="btn">Actualizeaza status</button>
  <Dialog
    :open="isOpen"
    @close="setIsOpen"
    class="fixed inset-0 z-10 overflow-y-auto"
  >
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="min-h-screen px-4 text-center">
        <DialogOverlay class="fixed inset-0 bg-black opacity-30" />

        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div
          class="
            inline-block
            w-full
            max-w-md
            p-6
            my-8
            overflow-hidden
            text-left
            align-middle
            transition-all
            transform
            bg-white
            shadow-xl
            rounded-2xl
          "
        >
          <DialogTitle
            as="h3"
            class="text-lg font-medium leading-6 text-gray-900"
          >
            Actualizeaza status comanda vanzator
          </DialogTitle>

          <select-vendor-order-transition
            v-model="orderStatus"
            :order-id="props.orderId"
            class="mt-2 w-full"
          />

          <div class="mt-4 flex justify-between">
            <button type="button" class="btn-primary" @click="updateStatus">
              Actualizeaza
            </button>
            <button type="button" class="btn" @click="setIsOpen(false)">
              Anuleaza
            </button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import ordersApi from "../api/resources/ordersApi";
import { ref, defineProps, defineEmits } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import SelectVendorOrderTransition from "@/components/shared/SelectVendorOrderTransition.vue";

const props = defineProps({ orderId: [String] });
const emit = defineEmits(["update:order"]);

const isOpen = ref(false);
const orderStatus = ref("");

function updateStatus() {
  ordersApi
    .update(props.orderId, { status_id: orderStatus.value })
    .then((response) => {
      emit("update:order", response.data);
      setIsOpen(false);
    });
}

function setIsOpen(value) {
  isOpen.value = value;
}
</script>
